import React, { useState } from 'react'
import Layout from '../components/Layout'
import '../styles/global.scss'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { devices } from '../styles/devices'

import BannerScience from '../components/BannerScience'
import BannerScienceSecond from '../components/BannerScienceSecond'
import ContactUs from '../components/ContactUs'
import ReferenceSite from '../components/ReferenceSite'
import BoxReferenceSite from '../components/BoxReferenceSite'
import Footer from '../components/Footer'
import { ButtonStyle } from '../components/ButtonCTA'
import axios from 'axios'
import { navigate } from 'gatsby'

const InputStyle = styled.input`
    border: 2px solid black;
    min-height: 30px;
    width: 100%;
    padding: 10px 2px;
    margin: 10px 0px;
`

const FileInputStyle = styled.input`
    min-height: 30px;
    width: 100%;
    padding: 10px 2px;
    margin: 10px 0px;
`

const TextareaStyle = styled.textarea`
    border: 2px solid black;
    min-height: 30px;
    width: 100%;
    padding: 10px 2px;
    margin: 10px 0px;
    height: 400px;
`

const Container = styled.div`
    max-width: 600px;
    padding: 24px 4px;
`
export default function science() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const submit = event => {
        event.preventDefault();
        if(name === ""){
            alert("You must enter a valid name")
            return
        }else if(email === ""){
            alert("You must enter a valid email address")
            return
        }
        const fullname =  name.split(" ");
        const fname = fullname[0]
        const lname = fullname[1]
        const url = 'https://api.sendgrid.com/v3/marketing/contacts'
        const token = process.env.GATSBY_MY_TOKEN
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            }
        }
        const contact = {
            "list_ids": [
                "17577248-ca25-488c-840f-ab764ff4e79c"
            ],
            "contacts": [
            {
                "email": email,
                "first_name": fname,
                "last_name":lname,
                "custom_fields": {
                    "test": "test"
                }
            }
        ]}

        axios.put(url,contact,config).then((res) =>{
            if(res.statusText === 'Accepted'){
                alert("Thank you ! for subscribing")
                navigate("/")
            }else{
                alert("We're sorry. This email has already been used to subscribe to this campaign.")
            }
        })
    }

    return (
        <div className="container">
            <Helmet>
                <title>Healthy Life | Bug Report</title>
            </Helmet>
            <Layout>

                <p>Help our team improve Healthy App</p>
                <Container>
                    <form method="post">
                        <label>Name</label>
                        <InputStyle name="name" id="name" type="text" onChange={e => setName(e)} value={name} placeholder="Name *" required/>
                        <label>Email</label>
                        <InputStyle name="email" id="email" type="email" onChange={e => setEmail(e)} value={email}  placeholder="Email *" required/>
                        <label>Describe the issue</label>
                        <TextareaStyle onChange={setDescription}/>
                        <label>Screenshots</label>
                        <FileInputStyle name="file" id="file" type="file" placeholder="File *" required/>
                        <ButtonStyle onClick={submit} color ="#2ec4b6" width="20%" borderRadius="5px">Submit Feedback</ButtonStyle>
                    </form>
                </Container>
                <Footer/>
            </Layout>
        </div>
    )
}

import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'

export const ButtonStyle = styled.div`
    color: black;
    display: block;
    padding: 16px 24px;
    background: ${(props) => props.color};
    white-space: nowrap;
    border-radius: 4px;
    width: ${(props) => props.width};
    text-align: center;
    font-weight: 600;
    border: ${(props) => props.border};
    cursor: pointer;
    @media (max-width: ${devices.sm}) {
        width: ${(props) => props.width};
    }
`

class ButtonCTA extends React.Component<{text: string, width: string, color: string, border: string, link: string}> {
    
    render() {
        const { text, color, width, border,link } = this.props
        return (
            <ButtonStyle color={color} width={width} border={border} >
              <a href={link} style={{textDecoration: 'none',color: '#000',cursor: 'pointer'}}>  {text} </a>  
            </ButtonStyle>
        )
    }
}
export default ButtonCTA
